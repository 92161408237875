﻿/*
/*
    We support multiple tenants in this application and every added css style can have a geometric impact 
    on maintenance. Please follow these guidelines.

    We have per/tenant appearance for controls and the app in general. A description of how we handle branding
    comes after the description of styles and classes that follows immediately.

    - STYLES AND CLASSES

    There are a couple high level div classes that contain selectors for the controls that appear within them.
    For the most part, there should be no need to define some new button class for instance.   It should be 
    handled by virtue of being within a div that has a [button] selector defining how all buttons look and feel.

    Try to keep specific size and positioning in inline styles unless absolutely necessary.  If it 
    is truly a class based setting, i.e. everything of that class should have a 30px left margin then it's 
    appropriate to put it in the class but if it's just another instance of a button like every other button
    and this one happens to have a 30px left margin then put it in the inline style.  Again: branding, if you
    create a new button it has to support EVERY tenant.   

    So for example, I need a button that's pretty much like every other button with the exception that it needs
    a 50px right margin.   Rather than this in the CSS:

    .my-button {
        <styles>
        margin-right: 50px
    }

    Do this in the tsx:

    <button style={{ marginRight: '50px' }} ... />

    If you have a button style that's going to CONSISTENTLY have a 50px margin in lots of places, i.e. it's a class
    then you can do something like 

    .my-button {
        margin-right: 50px 
    }

    And use that in addition to other classnames but don't be adding any size or appearance data to
    my-button.  Ideally, there would be no other classnames already on the button since a selector on 
    the containing div for [button] would define it's look and feel.

    Specifically the .control-region-lender and .control-region-tenant-<tenantname> settings already defined here
    have a [button] selector that defines the behavior for buttons.

    - CLASSES FOR UI STYLING: 
    
    There are a couple high level class names used for laying out the major sections of
    the pages.  This is a description of those and how they are used with a couple examples. In an effort
    to keep a minimum of styles, prevent a proliferation of unnecessary class names and consistency of 
    appearance follow these guidelines.  

    1. full-screen-region: This is intended to set a div to be a full screen region containing content of a fixed size
    with enough height at the top for the banner.   It's not for defining content styles or anything else, it's just a 
    full screen background.  An example is the login page.  full-screen-region is used for defining the background region 
    under the banner and that is all.  

    2. free-content-region: Defines the primary background designed to be a full screen region where the content size is 
    dynamic, typically something like a list that could extend past the end of the page. A good example of this is the 
    list of workspaces for a lender. Usually this will be applied to a div following a <PortalHeader />

    3. content-region: Defines a region that would represent essentially a rectangular area on the main background.
    A good example is the login page where the content is on a shaded background.  This is primarily about positioning
    with the basic idea of having a single div centered on the background area. It's effectively a single row consuming
    the entire background with a single piece of content vertically centered in that row. That centered div represents the
    region containing the content.  This is designed to be used in the following way with two divs:

    <div className={'content-region'}>
        <div>
            content.
        </div>
    </div>

    The content-region style defines the properties for the contained div that is an immediate child.

    4. contained-content, form-content: Used in conjunction with content-region for instance, defines standard 
    appearance for content that lives on a fixed region in a page.  As opposed to content-region that positions 
    the content, this defines a basic background for it, consistent shadow, padding and such.  The workspace list
    page uses a contained-content div under a free-content-region-div for holding the list.

    5. control-region-lender: This one is pretty important, it defines style and appearance for controls that appear within
    a page. If you are adding a control, e.g. an edit field to a page generally speaking you should not be adding a 
    class to it.  For example, within control-region-lender there's a selector for input[type=text].  By virtue of using an 
    input control you should be good.   There are slight variations on that such as input[type=text].large that defines
    the large input fields on the login screen.  

    BE VERY DELIBERATE ABOUT ADDING NEW STYLES, THERE NEEDS TO BE A REALLY GOOD REASON TO ADD A NEW ONE. IF YOU ADD A 
    NEW STYLE YOU HAVE TO MAKE SURE BRANDING IS HANDLED CORRECTLY. For both reasons of consistency and maintenance keep 
    styles to a minimum.

    6. lender-region, borrower-region: Simply defines appearance for a lender or borrower screen to be used in 
    conjunction with something like full-screen-region, so 'full-screen-region lender-region' would be a full 
    screen background with the lender color in the background.  At the moment neither of these is branded and 
    the colors are pretty close but slightly different.

    - HOW BRANDING WORKS

    We have a single code base that supports multiple tenants.  I'm not addressing how the favicon or portal banner
    img get branded here, just how the css works. We support the ability to have different UI properties depending
    on the tenant.  The process for doing this is fairly straight forward and takes little effort per tenant.  A theming
    scheme could be considered in the future and that might possibly be easier to maintain but not necessarily.  So...

    Many of the classes are broken up into a 'main' class and a 'tenant class'.  The main class defines structural things 
    size, shape, position.  The tenant class piece defines color and appearance type things that could be different per
    tenant.   This file contains most of the strongbox tenant settings while a file for each of the brands contains the
    tenant settings specific to those brands and color constants specific to the brands.   All of the tenant files are 
    found in the 'brands' subfolder of clientapp\src\components.   There are a couple other files where strongbox settings
    are found, namely strongboxlinker/strongboxlinkmodal.scss, Modal/Modal.scss and
    Button/Button.scss.  These are (primarily) holdovers from bringing code over from Flight on a tight deadline.  Don't
    follow this per window model going forward. 

    Returning to the definition of the classes.   Each class thus has two versions, one of name <classname> and one of name
    <classname>-tenant-<tenantname>.  Using control-region-lender as an example.  This file contains two class definitions.  

    .control-region-lender {
    }

    .control-region-tenant-strongbox {
    }

    In each of the brand files you will MAY find one or more corresponding tenant versions. So for example 
    in BDO.scss you will find

    .control-region-tenant-bdo {
    }

    Go take a look at each of these classes to fully understand what they contain.  It is pretty straight forward.

    When these classes are used you include both default class names plus the tenant name.  The tenant store has a 
    selector for getting the active tenant name.   Look in any of the MapDispatchToProps methods to see this used 
    but typically it will set a prop called something like 'tenantName'.   For an example go to the end of the 
    WorkspaceList.tsx file.  Using control-region-lender as an example, here is what the use would typically look like:

    <div className={`control-region-lender control-region-tenant-strongbox control-region-tenant-${props.tenantName}`}>
    </div>

    Pretty straight forward and everything changes dynamically at runtime.

    The trick here is that the browser will use the class defined last in a style sheet to override any versions
    of that class preceding it.  We include all of our tenant scss files at the end of this file so the tenant
    specific version will override the default strongbox version IF it exists.  If it doesn't, the default version
    will be used.   See the template file 'Template.scss' for a little broader description.
*/

@import '../Styles/_siteGlobal.scss';
@import './SharedConstants.scss';

/*
Base set of branding colors.  This list comprises the basic set you would need for adding
    a minimal new tenant.  Minimal is fine, probably all that's required though there could
    potentially be more added as in the case of BDO.  Copying this set and appending the
    tenant name would give you the root set required to add a tenant.

$formBackground: white;
$normalButtonColor: #01bbc8;
$normalButtonTextColor: white;
$primaryText: #1b1443;
$columnHeaders: #707070;
(--row-dividers): rgba(118,118,118,0.1);
$rowText: black;
$borderColor: $borderColor;
$emphasisText: #1b1443;
$legalText: #656565;
$superHappyText: #black;
$targetText: #2e2e2e;
$inputTextColor: rgba(32,32,32,0.6);
var(--input-background): #e8e8e8;
$paragraphText: #656565;
$controlRegion: #FBFBFB;
$hoverButtonColor: #96C357;
$hoverButtonColorActive: #A4CB6E;
$inputBorder: #707070;
$imgShadow: rgba(112, 112, 112, 0.4);
$bulletBackground: #e5f8f9;
$verifiedColor: $colorGood;
$unverifiedColor: $lightGrayBackground;
*/

:root {
    --background: #e5f8f9;
    --hilite-color: #01bbc8;
    --lender-background: #fcfcfc;
    --disabled-button-opacity: 0.3;
    --div-outline-color: lightgray;
    --modal-padding: 24px;
}

$small-screen-separator-max: 800px;
$small-screen-separator-min: 801px;
$med-screen-separator-max: 1100px;
$med-screen-separator-min: 1101px;

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.container {
    margin-right: 0;
    margin-left: 0;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.borrower-content-container {
    position: absolute;
    top: 0px;
    bottom: 50px;
    width: 100%;
    z-index: 1;
    color: var(--primary-text);
    font-family: var(--font-family-primary);

    .tos {
        color: var(--legal-text);
    }

    .progress {
        background-color: var(--borrower-progress-background) !important;

        .bg-info {
            background-color: var(--borrower-progress-background) !important;
        }
    }

    .know-more {
        font-weight: bold;
    }

    .estimated-time {
        text-align: center;

        @media (max-width: $small-screen-separator-max) {
            margin-top: 15px;
        }

        @media (min-width: $small-screen-separator-min) {
            margin-top: 50px;
        }

        span {
            text-decoration: none;
        }
    }

    @media (max-width: $small-screen-separator-max) {
        padding-top: 60px;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    @media (min-width: $small-screen-separator-min) {
        padding-top: 100px;
        padding-right: 0px;
        padding-bottom: 15px;
    }

    .normal-link-color {
        color: black;
        text-decoration: underline;
    }

    h1 {
        font-weight: bold;
        font-size: 48px;
    }

    h2 {
        font-weight: normal;
        font-size: 16px;
    }

    h3 {
        font-weight: bold;
        font-size: 16px;
    }

    h4 {
        font-weight: normal;
        font-size: 12px;
        color: var(--legal-text);
    }

    .p2 {
        margin-top: 30px;
    }

    .accounting-button-container {
        margin-top: 20px;

        .accounting-button-group-column {
            padding-top: 10px;
        }

        .accounting-button-summary-column {
            padding-top: 10px;
            text-align: left;
            position: relative;

            .accounting-package-image {
                width: 100%;
            }

            .bottom-row {
                margin-top: 35px;
                margin-bottom: 15px;
            }

            .bottom-column {
                display: flex;
                justify-content: center;
            }

            span {
                text-decoration: none;
                font-size: 16px;
            }

            .text-row {
                margin-top: 15px;
            }

            .instruction-row {
                height: 100%;
            }

            .instructions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px 20px 20px 20px;
            }

            .no-selection-image {
                width: 100%;
                height: fit-content;
            }

            .accounting-image-column {
                display: flex;
                justify-content: center;
            }

            .banner-image {
                width: 100%;
            }
        }

        .button-group-container {
            div.supplemental-row {
                @media (max-width: $med-screen-separator-max) {
                    margin-top: 20px;
                }

                @media (min-width: $med-screen-separator-min) {
                    margin-top: 40px;
                }
            }

            span {
                @media (max-width: $med-screen-separator-max) {
                    font-size: 14px !important;
                }

                @media (min-width: $med-screen-separator-min) {
                    font-size: 18px !important;
                }
            }

            table {
                margin-bottom: unset;
            }

            table tr {
                text-align: left;
                color: var(--light-gray-background-text);
                border: none;
            }

            tr th {
                padding: 2px 0px 17px 5px;
                border: none;
            }

            th span {
                font-size: 18px;
                font-weight: bold;
            }

            table td {
                padding: 2px 5px 2px 35px;
                text-align: left;
                background-color: var(--form-background);
                border: none;
                cursor: pointer;
            }

            table td.disabled {
                cursor: not-allowed;
            }

            table span {
                text-decoration: none;
            }

            table span.group-name {
                cursor: default;
            }

            table span.accounting-package:hover {
                border: 1px solid var(--dark-border);
                border-radius: 0;
                padding: 2px 15px 2px 5px;
            }

            table span.disabled {
                cursor: not-allowed;
            }
        }
    }

    .buttons {
        margin-top: 30px;

        .button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 60px;
            padding: 5px;
            text-align: center;

            div.beta-descriptor {
                justify-content: center;

                h1 {
                    font-size: 16px;
                    font-weight: bold;
                    padding-top: 6px;
                    color: var(--beta-warning-text);
                    background: var(--beta-warning-background);
                    margin-top: 10px;
                    margin-bottom: 0px;
                    border-radius: 20px;
                    width: 65px;
                    height: 32px;
                }
            }
        }
    }

    .additional-actions-container {
        margin-top: 30px;

        .send-email-image {
            background-image: url("../images/borrower-email.png");
        }

        .repeat-import-image {
            background-image: url("../images/borrower-repeat.png");
        }

        .upload-additional-image {
            background-image: url("../images/borrower-upload.png");
        }
    }

    .active-action-container {
        margin-top: 25px;
    }

    .regular-paragraph-text-grouping {
        font-weight: normal;

        @media (max-width: $small-screen-separator-max) {
            margin-top: 5px;
            font-size: 16px;
        }

        @media (min-width: $small-screen-separator-min) {
            margin-top: 15px;
            font-size: 20px;
        }
    }

    .leading-paragraph-text-grouping {
        font-weight: normal;
        color: var(--borrower-primary-text);
        max-width: 525px;

        @media (max-width: $small-screen-separator-max) {
            margin-top: 25px;
            font-size: 12px;
        }

        @media (min-width: $small-screen-separator-min) {
            margin-top: 25px;
            font-size: 14px;
        }
    }

    .ellipse-list-separator {
        @media (max-width: $small-screen-separator-max) {
            margin-right: 20px;
        }

        @media (min-width: $small-screen-separator-min) {
            margin-right: 35px;
        }
    }

    .basic-height-separator {
        @media (max-width: $small-screen-separator-max) {
            margin-top: 10px;
        }

        @media (min-width: $small-screen-separator-min) {
            margin-top: 25px;
        }
    }

    span {
        color: black;
        text-decoration: underline;
    }

    span:hover {
        cursor: pointer;
    }

    .error-text {
        font-family: var(--font-family-primary);
        color: var(--error-text);
        text-decoration: none;
    }

    .error-text:hover {
        cursor: default;
    }

    .borrower-content-row {
        justify-content: center;
        text-align: center;
    }

    .borrower-tos-row {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: var(--borrower-background-color);
    }

    .regular-button {
        padding: 16px 40px 16px 40px;
        font-size: 24px;
        border-radius: 0px;
        font-weight: bold;
        outline: none;
        border: none;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        height: auto;
        width: auto;
        display: inline-flex;
        align-items: center;
        margin: 0;
        background-color: var(--normal-button-color);
        color: var(--normal-button-text-color);
    }

    .regular-button:focus {
        outline: 3px solid var(--input-border);
        outline-offset: 2px;
    }

    button.secondary-button {
        background-color: var(--secondary-button-color);
        color: var(--secondary-button-text-color);
    }

    .regular-button:disabled {
        background-color: var(--disabled-button-color-borrower);
        color: var(--disabled-button-text-color-borrower);
    }

    .regular-button:active {
        transform: translateY(2px) translateX(2px);
    }

    button.small {
        @media (max-width: $small-screen-separator-max) {
            padding: 8px 14px 8px 14px;
            font-size: 12px;
        }

        @media (min-width: $small-screen-separator-min) {
            padding: 12px 20px 12px 20px;
            font-size: 16px;
        }
    }

    .borrower-col {
        position: relative;
        height: auto;
        background-color: var(--borrower-background-color);
        border-radius: 10px;
    }

    .borrower-content {
        background: inherit;
        z-index: 0;
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        padding: 25px 60px 25px 60px;
        overflow-y: auto;
        border-radius: 0;
        color: var(--borrower-primary-text);

        p {
            @media (max-width: $small-screen-separator-max) {
                font-size: 14px;
            }

            @media (min-width: $small-screen-separator-min) {
                font-size: 20px;
            }
        }

        p.supplemental {
            font-weight: bold;
            text-align: left;

            span {
                text-decoration: none;
                cursor: auto;
            }

            span.clickable {
                cursor: pointer;
                text-decoration: underline;
            }

            @media (max-width: $small-screen-separator-max) {
                font-size: 12px;
            }

            @media (min-width: $small-screen-separator-min) {
                font-size: 16px;
            }
        }

        a {
            @media (max-width: $small-screen-separator-max) {
                font-size: 14px;
            }

            @media (min-width: $small-screen-separator-min) {
                font-size: 20px;
            }
        }

        span {
            @media (max-width: $small-screen-separator-max) {
                font-size: 14px;
            }

            @media (min-width: $small-screen-separator-min) {
                font-size: 20px;
            }

            font-weight: normal;
        }

        span.light {
            font-size: 14px;
        }

        span.heavy {
            @media (max-width: $small-screen-separator-max) {
                font-size: 16px;
            }

            @media (min-width: $small-screen-separator-min) {
                font-size: 24px;
            }

            font-weight: bold;
        }

        span.emphasized-paragraph-element {
            font-weight: bold;
        }

        span.link {
            font-weight: bold;
            font-size: 16px;
            text-decoration: underline;
            cursor: pointer;
            white-space: nowrap;
        }

        span.disabled-link {
            text-decoration: none;
            cursor: unset;
        }

        span.small-link {
            font-weight: bold;
            font-size: 16px;
            text-decoration: underline;
            cursor: pointer;
        }

        .regular-input {
            @media (max-width: $small-screen-separator-max) {
                width: 200px;
            }

            @media (min-width: $small-screen-separator-min) {
                width: 300px;
            }
        }

        h1 {
            @media (max-width: $small-screen-separator-max) {
                font-size: 16px;
            }

            @media (min-width: $small-screen-separator-min) {
                font-size: 18px;
            }

            font-weight: bold;
        }

        h2 {
            @media (max-width: $small-screen-separator-max) {
                font-size: 14px;
            }

            @media (min-width: $small-screen-separator-min) {
                font-size: 20px;
            }

            font-weight: bold;
            color: var(--borrower-primary-text);
        }

        h2.normal {
            font-weight: normal;
        }

        h2 > span {
            @media (max-width: $small-screen-separator-max) {
                font-size: 14px;
            }

            @media (min-width: $small-screen-separator-min) {
                font-size: 20px;
            }

            font-weight: bold;
            text-decoration: underline;
        }

        h2 > span.normal {
            font-weight: normal;
        }

        h2 > span.normal:hover {
            cursor: pointer;
        }

        h3 {
            font-size: 18px;
            font-weight: normal;
        }

        h3 > span {
            font-size: 18px;
            font-weight: normal;
            text-decoration: underline;
        }

        h3 > span:hover {
            cursor: pointer;
        }

        h4 {
            font-size: 13px;
            font-weight: bold;
        }

        .clear-image {
            box-shadow: none;
        }

        .clear-image-fade-in {
            box-shadow: none;
            opacity: 0;
            transition: opacity 0.75s;
            color: var(--strongbox-orange);
        }

        .clear-image-fade-in-visible {
            opacity: 1;
            color: var(--strongbox-orange);
        }

        table.line-items {
            tbody {
                text-align: left;
            }

            tr {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 10px;
                font-size: 20px;
            }

            td {
                padding: 10px;
            }
        }

        ul.normal-bullets {
            text-align: left;
        }

        .centered-col {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;
        }

        .large-icon {
            height: 60px;
            width: 60px;
        }

        .outlined-column-content {
            display: inline-block;
            flex-direction: unset;
            justify-content: space-between;
            align-items: flex-start;
            outline: 1px solid var(--dark-border);
            padding: 25px;
            text-align: left;

            div {
                justify-content: space-between;
            }
        }

        svg.strongbox-logo {
            margin-top: 7%;
            width: 60%;
            height: 100%;
        }

        .intro-banner-content {
            font-size: 28px;
            font-weight: bold;

            h3 {
                font-size: 28px;
                font-weight: bold;
            }
        }
    }

    .large-icon {
        color: var(--strongbox-orange);
    }

    .file-upload-container {
        height: 500px;
    }

    .shareable-link-error-msg-row {
        p {
            text-align: left;
        }
    }
}

.borrower-window-container-shadow-cover {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(.25turn, rgba(57, 57, 57, 1.0), 90%, rgba(225, 225, 225, 1.0));
    z-index: -2;
}

.borrower-window-container {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 100%;
}

.borrower-region {
    background-color: var(--background);
    top: var(--banner-height-borrower);
}

.lender-region {
    background-color: var(--lender-background);
    top: var(--banner-height-lender);
}

.full-screen-region {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.workspace-list {
    table {
        width: 100%;

        button {
            width: 40px;
            height: 40px;
            padding: 0;
        }

        button.text {
            height: 40px;
            padding: 0;
            width: 165px;
            display: flex;
            justify-content: center;
            white-space: nowrap;
        }
    }

    .column-headers-unbordered {
        color: var(--column-headers);
        font-family: var(--font-family-primary);
        font-size: 16px;
    }

    .column-headers {
        color: var(--column-headers);
        border-bottom: 2px solid var(--border-color);
        border-top: 1px solid var(--row-dividers);
        font-family: var(--font-family-primary);
        font-size: 16px;

        th, td {
            padding-bottom: 19px;
            padding-top: 31px;
        }

        .name {
            width: 33%;
            max-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .date {
            width: 20%;
            max-width: 0;
            word-break: break-word;
            overflow-wrap: break-word;
            padding-left: 15px;
        }
    }

    .normal-rows {
        border-bottom: 1px solid var(--row-dividers);
        font-family: var(--font-family-primary);

        accent-color {
            color: var(--row-text);
        }

        th, td {
            padding-bottom: 26px;
            padding-top: 33px;
            font-size: 16px;
            font-weight: normal;
            color: var(--row-text);
        }

        .name {
            font-size: 16px;
            font-weight: bold;
            width: 25%;
            max-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--row-text);
        }

        .newest-submission-actions {
            width: 20%
        }

        .date {
            width: 14%;
            max-width: 0;
            word-break: break-word;
            overflow-wrap: break-word;
            padding-left: 15px;
        }

        .actions {
            padding-left: 5px;
        }

        .btn {
            margin: 0px;
        }

        .btn-secondary {
            margin: 0px;
        }

        .disabled {
            color: var(--disabled-text);
        }
    }

    .normal-rows-action-container {
        .newest-submission-actions-container {
            visibility: hidden;
            font-size: 14px;

            button {
                background: transparent;
                display: inline-flex;
                justify-content: center;
            }
        }
    }

    .normal-rows-action-container:hover {
        .newest-submission-actions-container {
            visibility: visible;
        }
    }

    .normal-rows-action-container-lock-visible {
        .newest-submission-actions-container {
            font-size: 14px;

            button {
                background: transparent;
                display: inline-flex;
                justify-content: center;
            }

            svg {
                color: var(--row-text);
            }
        }
    }
}

.submissions-list {
    height: 100%;
    padding-left: 0px;

    .full-height-settings-row {
        height: 100%;
        position: relative;
    }

    .most-of-remaining-height-row {
        height: 90%;
    }

    .settings-container-title-row {
        align-items: center;
        margin-top: 2em;
        margin-bottom: 1em;
    }

    table {
        width: 100%;
    }

    .content-container {
        overflow-x: auto;
    }

    div.vertical-list {
        display: flex; 
        flex-direction: column;

        span.header {
            font-weight: bold;
            margin-right: 10px;
        }

        div {
            display: flex;
            flex-direction: row;
        }
    }

    .deleted-submission {
        background-color: var(--deleted-row-background);
    }
}

.control-region {
    font-family: var(--font-family-primary);
    color: var(--primary-color);

    a {
        font-weight: bold;
        color: var(--primary-text);
    }

    .anchor-text {
        font-weight: bold;
    }

    a.small {
        font-weight: bold;
    }

    a.underline, span.underline {
        text-decoration: underline;
    }

    .underlined-text {
        text-decoration: underline;
    }

    button {
        border-radius: 0px;
        padding: 16px 40px 16px 40px;
        font-weight: bold;
        font-size: 24px;
        outline: none;
        border: none;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        height: auto;
        width: auto;
        display: inline-flex;
        align-items: center;
        margin: 0;
        background-color: var(--normal-button-color);
        color: var(--normal-button-text-color);
    }

    button:disabled {
        background-color: var(--disabled-button-color);
        opacity: var(--disabled-button-opacity);
    }

    button:active {
        transform: translateY(2px) translateX(2px);
    }

    button.secondary-button {
        background-color: var(--secondary-button-color);
        color: var(--secondary-button-text-color);
    }

    button.medium {
        padding: 14px 45px 14px 45px;
        font-size: 24px;
    }

    button.small {
        padding: 8px 12px 8px 12px;
        font-size: 14px;
        white-space: nowrap;
    }

    button.extrasmall {
        padding: 6px 10px 6px 10px;
        font-size: 14px;
        white-space: nowrap;
    }

    button.simple-icon {
        padding: 4px;
        height: 18px;
        width: 18px;
        background-color: transparent;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    }

    button.simple-icon:active {
        transform: translateY(2px) translateX(2px);
    }

    button.focus-button {
    }

    button.focus-button:focus {
        outline: 3px solid var(--input-border);
        outline-offset: 2px;
    }

    .submissions-button-icon {
        display: flex;
        justify-content: center;
        height: 28px;
        width: 32px;
        background-color: transparent;
        padding: 1px 1px 1px 3px;
    }

    .submissions-icon {
        height: 24px;
        width: 24px;
        mask-repeat: no-repeat;
    }

    .small-accounting-button {
        margin-left: 10px;
        justify-content: center;
        padding: 0px;
    }

    .shadowed-accounting-button {
        width: 28px;
        height: 28px;
        padding: 0px;
        margin-left: 10px;
    }

    .small-accounting-icon {
        height: 22px;
        width: 24px;
        border-radius: 5px;
    }

    .icon-buttons {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        color: var(--menu-gray-text);
    }

    .basic-icon-size {
        width: 24px;
        height: 24px;
    }

    .simple-icon-button {
        padding: 0;
        display: flex;
        justify-content: center;
    }

    input[type=text], input[type=email], input[type=number] {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5px;
        border-radius: 0;
        border: 1px solid var(--input-border);
        background-color: var(--input-background);
        color: var(--input-text-color);
    }

    input[type=button] {
        background-color: var(--normal-button-color);
        border-radius: 0px;
        padding: 16px 40px 16px 40px;
        color: var(--normal-button-text-color);
        font-weight: bold;
        font-size: 24px;
        outline: none;
        border: none;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    }

    input[type=button]:active {
        transform: translateY(2px) translateX(2px);
    }

    input[type=button].small {
        padding: 12px 20px 12px 20px;
        font-size: 16px;
    }

    input[type=text].large, input[type=password].large, input[type=email].large {
        height: auto;
        font-size: 16px;
        padding-left: 16px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 2px;
        border-left: none;
        border-top: none;
        border-right: none;
        background-color: var(--input-background);
        color: var(--input-text-color);
        border-bottom: 1px solid var(--input-border);
    }

    input[type=text].small {
        font-size: 16px;
        height: auto;
    }

    input[type=text]:focus, input[type=email]:focus {
        outline: none;
    }

    input[type=password]:focus {
        outline: none;
    }

    label.large {
        height: auto;
        font-size: 24px;
    }

    p {
        font-weight: normal;
        font-size: 16px;
        color: var(--paragraphtext);
    }

    .right-image {
        float: right;
    }

    span {
        font-weight: normal;
        font-size: var(--font-default-control-span-size);
        color: var(--emphasis-text);
    }

    span.xsmall {
        font-weight: bold;
        font-size: 12px;
    }

    span.light {
        font-size: 14px;
    }

    span.heavy {
        font-weight: bold;
    }

    span.emphasized-paragraph-element {
        font-weight: bold;
        color: var(--paragraphtext);
    }

    span.link {
        font-weight: bold;
        font-size: 16px;
        text-decoration: underline;
        cursor: pointer;
        white-space: nowrap;
    }

    span.disabled-link {
        text-decoration: none;
        cursor: unset;
    }

    span.small-link {
        font-weight: bold;
        font-size: 16px;
        text-decoration: underline;
        cursor: pointer;
    }

    h1 {
        font-weight: bold;
        color: var(--super-happy-text);
    }

    h2 {
        font-weight: bold;
        color: var(--emphasis-text);
    }

    h2.normal {
        font-weight: normal;
    }

    h2 > span {
        font-weight: bold;
        text-decoration: underline;
    }

    h2 > span.normal {
        font-weight: normal;
    }

    h2 > span.normal:hover {
        cursor: pointer;
    }

    h3 {
        font-weight: normal;
        color: var(--paragraphtext);
    }

    h3 > span {
        font-weight: normal;
        text-decoration: underline;
    }

    h3 > span:hover {
        cursor: pointer;
    }

    h4 {
        font-weight: bold;
    }

    .error-text {
        font-weight: normal;
        font-size: 16px;
        color: var(--error-text);
    }

    hr {
        width: 100%;
        border: 1px solid var(--border-color);
    }

    .clear-image {
        box-shadow: none;
    }

    .clear-image-fade-in {
        box-shadow: none;
        opacity: 0;
        transition: opacity 0.75s;
    }

    .clear-image-fade-in-visible {
        opacity: 1;
    }

    table.line-items {
        tr {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;
        }

        td {
            padding: 10px;
        }
    }

    table.normal-list {
        width: 100%;

        tr {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-top: 1px solid var(--row-dividers);
        }

        tr.litehilite {
            background-color: var(--light-gray-background);
            border-radius: 0;
        }

        td {
            border: unset;
            text-align: center;
            background-color: transparent;
        }

        th {
            border: unset;
            text-align: center;
            background-color: transparent;
        }

        th.icon, td.icon {
            width: 32px;
            height: 32px;
            padding: unset;
            display: flex;
            align-items: center;
        }

        th > div {
            display: flex;
            flex-direction: column;
            height: auto;
            justify-content: flex-end;
        }

        th > div > span {
            margin-bottom: 10px;
        }

        th.xs {
            width: 15%;
        }

        td.xs {
            width: 15%;
        }

        th.remainder {
            width: 100%;
            text-align: left;
        }

        td.remainder {
            width: 100%;
            text-align: left;
        }

        td.left {
            text-align: left;
        }

        th.left {
            text-align: left;
        }

        span {
            font-size: 14px;
        }

        span.vcentered {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        button {
            padding: 0;
            background-color: transparent;
            cursor: pointer;
        }

        button.extrasmall {
            padding: 6px 10px 6px 10px;
            background-color: var(--normal-button-color);
            font-size: 14px;
            white-space: nowrap;
        }
    }

    table.normal-list-selectable {
        tr {
            width: 100%;
            padding-left: 15px;
            text-align: left;
            border: none;
        }

        tr.selected {
            background-color: var(--off-white-background);
            color: var(--light-gray-background-text);
        }

        tr:hover {
            cursor: pointer;
            border: 1px solid var(--light-gray);
        }

        tr.unselectable:hover {
            cursor: default;
            border: none;
        }
    }

    svg.button {
        cursor: pointer;
    }

    svg.button:active {
        transform: scale(0.90, 0.90);
        box-shadow: inset -10px -10px 5px var(--img-shadow);
        border-radius: 15px;
    }

    img {
        box-shadow: 2px 2px 8px var(--img-shadow);
    }

    div.dropdown {
        button {
            padding: 12px 20px 12px 20px;
            font-size: 16px;
            background-color: var(--input-background);
            color: var(--menu-gray-text);
        }

        .dropdown-item {
            box-shadow: none;
            width: 100%;
            background-color: transparent;
            color: var(--menu-gray-text);
        }

        .dropdown-item:active {
            background-color: var(--strongbox-orange);
        }

        .dropdown-item:hover {
            background-color: var(--light-background-hover);
            color: var(--menu-gray-text);
        }

        span.spacer {
            width: 20px;
        }
    }

    div.control-header-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;

        h1 {
            margin: 0px;
        }
    }

    .caret {
        border-radius: 0;
        padding: 0px 0px 0px 20px;
        font-size: 16px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        color: var(--menu-gray-text);
        background-color: var(--input-background);
        font-weight: bold;
        border-radius: 0;
    }

    ul.normal-bullets {
        li {
            font-size: 24px;
            margin-bottom: 10px;
        }

        span {
            font-weight: bold;
            font-size: 24px;
            text-decoration: underline;
        }

        span.normal {
            font-weight: normal;
        }

        span.normal:hover {
            cursor: pointer;
        }
    }

    ul.small-bullets {
        li {
            @media (max-width: $small-screen-separator-max) {
                margin-top: 12px;
            }

            @media (min-width: $small-screen-separator-min) {
                margin-top: 16px;
            }

            margin-bottom: 4px;
        }

        span {
            font-weight: bold;
            font-size: 18px;
            text-decoration: underline;
        }

        span.normal {
            font-weight: normal;
        }

        span.normal:hover {
            cursor: pointer;
        }
    }

    .custom-toggle {
        width: fit-content;
    }

    .custom-toggle.react-toggle--checked .react-toggle-track {
        background-color: var(--normal-button-color);
    }

    .custom-toggle.react-toggle--checked:hover .react-toggle-track {
        background-color: var(--normal-button-color);
    }

    input[type="checkbox"] {
        background-color: transparent;
        border-color: var(--normal-button-color);
    }

    input[type="checkbox"]:checked {
        background-color: var(--normal-button-color);
        border-color: var(--normal-button-color);
    }

    input[type="checkbox"].large {
        height: 16px;
        width: 16px;
    }

    input[type="checkbox"].form-check-input, input[type="radio"].form-check-input {
        background-color: transparent;
        border-color: var(--normal-button-color);
    }

    input[type="checkbox"].form-check-input:checked, input[type="radio"].form-check-input:checked {
        background-color: var(--normal-button-color);
        border-color: var(--normal-button-color);
    }

    input[type="checkbox"].form-check-input:indeterminate {
        background-color: var(--normal-button-color);
        border-color: var(--normal-button-color);
    }

    .react-datepicker__input-container {
        input {
            width: 100%;
        }
    }

    .react-datepicker__day--selected, .react-datepicker__day--selected:hover {
        background-color: var(--light-strongbox-orange);
    }

    .strongbox-basic-content-container {
        width: fit-content;
        min-width: 50%;
        max-width: 70%;
        height: fit-content;
        padding-bottom: 30px;
        padding-left: 45px;
        padding-right: 45px;
        background-color: var(--strongbox-basic-content-container-background);
        color: var(--strongbox-basic-content-container-text);

        .welcome-content-container-column {
            display: flex;
            flex-direction: row;
            padding-top: 20px;
        }

        span.welcome-image {
            width: 150px;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            position: relative;

            .hex-background {
                height: 100%;
                width: 100%;
                box-shadow: none;
                position: absolute;
                top: 0;
                left: 0;
            }

            .strongbox-logo {
                height: 55%;
                width: 55%;
                box-shadow: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }

        div.brand-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            span {
                font-size: 24px;
                font-weight: bold;
                padding-left: 15px;
            }

            div.separator-line {
                width: 100%;
                height: 3px;
                border-top: 3px solid var(--strongbox-orange);
                margin-bottom: unset;
            }

            div.ellipses-container {
                width: 175px;
                justify-items: center;
            }
        }
    }

    .login-content-container {
        width: fit-content;
        min-width: 50%;
        max-width: 70%;
        height: fit-content;
        padding-bottom: 30px;
        padding-left: 45px;
        padding-right: 45px;
        background-color: var(--strongbox-basic-content-container-background);
        color: var(--strongbox-basic-content-container-text);

        p.login-text {
            color: var(--login-text-color);
        }

        div.login-team-selection-container {
            display: flex;
            flex-direction: row;

            .login-content-column {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-bottom: 20px;
            }

            .team-selection-login-button {
                min-width: 200px;
                justify-content: center;
                padding: 10px 20px 10px 20px;
                font-size: 18px;
            }

            ul {
                height: fit-content;
                overflow-y: auto;
                border: none;
                padding: unset;
                border: 2px solid var(--normal-button-color);
                padding: 25px;
            }

            li {
                list-style-type: none;
                cursor: pointer;

                div {
                    height: 30px;
                    display: flex;
                    flex-direction: row;
                }

                div.list-text {
                    margin-left: 5px;
                }

                span {
                    padding: 3px 10px 0px 5px;
                }

                img {
                    box-shadow: none;
                    height: 20px;
                    width: 20px;
                    margin: 5px;
                    visibility: hidden;
                }
            }

            li:hover {
                img {
                    visibility: visible;
                }

                span {
                    background-color: var(--normal-button-color);
                    color: var(--normal-button-text-color);
                }
            }

            li.selected {
                img {
                    visibility: visible;
                }
            }
        }
    }

    div.separator-line {
        width: 140px;
        height: 3px;
        border-top: 3px solid var(--strongbox-orange);
        margin-bottom: 30px;
    }

    div.invalid-tooltip {
        border-radius: 0;
    }
}

.control-region-lender {
    a {
        font-size: 20px;
    }

    .anchor-text {
        font-size: 20px;
    }

    .anchor-text-small {
        font-size: 16px;
    }

    a.small {
        font-size: 16px;
    }

    span {
        font-size: var(--font-default-control-span-size);
    }

    span.light {
        font-size: 14px;
        color: var(--text-gray);
    }

    span.heavy {
        font-size: 24px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 20px;
    }

    h2 > span {
        font-size: 24px;
    }

    h3 {
        font-size: 18px;
    }

    h3 > span {
        font-size: 18px;
    }

    h4 {
        font-size: 16px;
    }

    .hex-button-action-container {
        .clickable-element {
            cursor: pointer;
        }

        button {
            background-image: url("../images/grey-hex.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            justify-content: center;
            align-items: center;
            border: none;
            display: flex;
        }

        button:focus {
            outline: 3px solid var(--input-border);
            outline-offset: 2px;
        }

        .hex-button-no-click {
            cursor: default;
        }

        .regular-hex-button {
            width: 132px;
            height: 132px;
        }

        .small-hex-button {
            width: 72px;
            height: 72px;
            border-radius: 0;
        }

        .single-line-hex-button {
            width: 40px;
            height: 40px;
        }

        .icon-container {
            background-size: cover;
            background-repeat: no-repeat;
        }

        .small-icon-container {
            width: 32px;
            height: 32px;
        }

        .single-line-icon-container {
            width: 16px;
            height: 16px;
        }

        .regular-icon-container {
            width: 64px;
            height: 64px;
        }

        svg {
            color: var(--additional-borrower-action-icon);
            height: 64px;
            width: 64px;
        }

        .hex-button-col {
            span {
                font-size: 16px;
                text-decoration: none;
                font-weight: bold;
                color: var(--borrower-primary-text);
                max-width: 150px;
                text-align: center;
            }

            button {
                box-shadow: none;
            }

            button.selected {
                border: 3px solid var(--border-color);
            }
        }

        .hex-button-col-multi-line {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .hex-button-col-single-line {
            display: flex;
            flex-direction: row;
            align-items: start;
        }

        .connect-shareable-link-image {
            background-image: url("../images/ShareableLink.svg");
        }

        .connect-direct-image {
            background-image: url("../images/DirectLink.svg");
        }

        .connect-excel-image {
            background-image: url("../images/ExcelLink.svg");
        }
    }

    .bolded-selector-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            font-weight: bold;
            font-size: 18px;
            margin-left: 10px;
            margin-top: 4px;
        }
    }

    .centered-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
    }

    .data-collection-import-container {
        width: 700px;
        padding-top: 20px;
        min-height: 550px;

        .data-items-table {
            p {
                margin: unset;
                padding-left: 30px;
            }

            label {
                margin: 0 0 0 10px;
                text-wrap: nowrap;
            }

            input {
                margin: unset;
            }

            td.selection-cell {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            td.description-cell {
                p {
                    margin-bottom: 15px
                }
            }
        }
    }

    div.error-banner {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        margin-bottom: 45px;
        padding: 15px;
        border: 1px solid var(--border-color);

        div.actions {
            margin-top: 20px;
            align-self: flex-end;
        }
    }
}

.control-region-borrower {
    a {
        @media (max-width: $small-screen-separator-max) {
            font-size: 14px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 20px;
        }
    }

    .anchor-text {
        @media (max-width: $small-screen-separator-max) {
            font-size: 14px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 20px;
        }
    }

    a.small {
        @media (max-width: $small-screen-separator-max) {
            font-size: 12px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 14px;
        }
    }

    span {
        @media (max-width: $small-screen-separator-max) {
            font-size: 12px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 16px;
        }
    }

    span.light {
        @media (max-width: $small-screen-separator-max) {
            font-size: 12px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 14px;
        }
    }

    span.heavy {
        @media (max-width: $small-screen-separator-max) {
            font-size: 18px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 24px;
        }
    }

    h1 {
        @media (max-width: $small-screen-separator-max) {
            font-size: 14px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 20px;
        }
    }

    h2 {
        @media (max-width: $small-screen-separator-max) {
            font-size: 14px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 20px;
        }
    }

    h2 > span {
        @media (max-width: $small-screen-separator-max) {
            font-size: 16px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 24px;
        }
    }

    h3 {
        @media (max-width: $small-screen-separator-max) {
            font-size: 14px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 18px;
        }
    }

    h3 > span {
        @media (max-width: $small-screen-separator-max) {
            font-size: 14px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 18px;
        }
    }

    h4 {
        @media (max-width: $small-screen-separator-max) {
            font-size: 12px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 16px;
        }
    }

    .bullet-image {
        margin-right: 15px;

        @media (max-width: $small-screen-separator-max) {
            width: 18px;
            height: 18px;
        }

        @media (min-width: $small-screen-separator-min) {
            width: 32px;
            height: 32px;
        }
    }

    .bullet-row {
        @media (max-width: $small-screen-separator-max) {
            margin-bottom: 15px;
        }

        @media (min-width: $small-screen-separator-min) {
            margin-bottom: 35px;
        }
    }

    .hex-button-action-container {
        button {
            background-image: url("../images/grey-hex.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            justify-content: center;
            align-items: center;
            border: none;
            display: flex;
        }

        button:focus {
            outline: 3px solid var(--input-border);
            outline-offset: 2px;
        }

        .regular-hex-button {
            width: 132px;
            height: 132px;
        }

        .small-hex-button {
            width: 72px;
            height: 72px;
            border-radius: 0;
        }

        .icon-container {
            background-size: cover;
            background-repeat: no-repeat;
        }

        .small-icon-container {
            width: 32px;
            height: 32px;
        }

        .regular-icon-container {
            width: 64px;
            height: 64px;
        }

        svg {
            color: var(--additional-borrower-action-icon);
            height: 64px;
            width: 64px;
        }

        .additional-action-col {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-size: 16px;
                text-decoration: none;
                font-weight: bold;
                color: var(--borrower-primary-text);
                max-width: 150px;
                text-align: center;
            }

            button {
                box-shadow: none;
            }

            button.selected {
                border: 3px solid var(--border-color);
            }
        }
    }
}

.left-bordered-horizontal-separator {
    @media (min-width: $xs-breakpoint) {
        border-left: 1px solid var(--light-gray);
        height: 100%;
    }
}

.right-bordered-region {
    border-right: 1px solid var(--light-gray);
    height: 100%;
}

.left-bordered-region {
    border-left: 1px solid var(--light-gray);
    height: 100%;
}

.top-bordered-region {
    border-top: 1px solid var(--light-gray);
}

.bottom-bordered-region {
    border-bottom: 1px solid var(--light-gray);
}

.bordered-region {
    border: 1px solid var(--light-gray);
    padding: 15px;
}

.basic-bordered-region {
    border: 1px solid var(--border-color);
}

.popover {
    max-width: 800px;
    color: var(--input-text-color);
}

.dropdown-content-region {
    display: flex;
    flex-direction: row;
}

.portal-header-region {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .free-heading {
        margin: unset !important;
    }
}

.portal-footer-container {
    width: 100%;
    position: fixed;
    bottom: 0px;
    display: flex;
    justify-content: center;

    .portal-footer-region {
        width: 65%;
        border-radius: 0px;
        padding: 5px 15px 10px 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid var(--border-color);
        background-color: var(--light-strongbox-orange);
        color: var(--normal-button-text-color);
    }

    a {
        color: var(--footer-anchor-text);
    }
}

.modal-terms-of-use {
    background-color: var(--off-white-background);
    width: 95%;
    border: 1px solid var(--border-color);
    border-radius: 0px;
    padding: 10px 20px 10px 20px;

    p {
        margin: 0;
    }
}

.modal-terms-of-use-bottom {
    position: absolute;
    bottom: 20px;
}

.free-content-region {
    padding-top: 30px;
    padding-left: 143px;
    padding-right: 143px;
    padding-bottom: 75px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    min-height: 600px;
}

.free-content-region-wide {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
}

.free-content-wide-content {
    padding-left: 30px;        
    padding-right: 30px;
}

.content-region {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-family: roboto, sans-serif;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.light-top-margin {
    padding-top: 30px;
}

.contained-content {
    display: flex;
    flex-direction: column;
    padding: 40px 40px 40px 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    min-width: 750px;

    .data-container {
        padding: 10px 15px 10px 15px;

        h1 {
            font-size: 16px;
            font-weight: bold;
        }

        h2, span {
            font-size: 16px;
            color: var(--text-gray);
            font-weight: normal;
        }

        label {
            font-size: 16px;
            color: var(--text-gray);
            font-weight: normal;
            margin: 0px;
        }

        .request-summary-row {
            input {
                margin: 0px;
            }

            div {
                display: flex;
                align-items: center;
            }
        }

        .error-text {
            color: var(--error-text);
        }
    }
}

.form-content {
    background-color: var(--form-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 65px 65px 65px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.banner-div-lender {
    color: var(--borrower-primary-text);
    font-family: var(--font-family-primary);

    @media (max-width: $small-screen-separator-max) {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 16px;
        padding-bottom: 16px;
        height: var(--banner-height-lender-slim);
    }

    @media (min-width: $small-screen-separator-min) {
        padding-left: 75px;
        padding-right: 75px;
        padding-top: 34px;
        padding-bottom: 34px;
        height: var(--banner-height-lender);
    }


    .full-height-column {
        @media (max-width: $small-screen-separator-max) {
            height: var(--banner-height-lender-slim);
        }

        @media (min-width: $small-screen-separator-min) {
            height: var(--banner-height-lender);
        }
    }
}

.banner-div-borrower {
    position: absolute !important;
    left: 0px;
    top: 0px;
    padding-bottom: 34px;
    height: var(--banner-height-borrower);
    color: var(--borrower-primary-text);
    font-family: var(--font-family-primary);

    .full-height-column {
        height: var(--banner-height-borrower);
    }
}

.banner-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
    background: transparent;

    h1 {
        @media (max-width: 600px) {
            font-size: 12px;
        }

        @media (min-width: 601px) and (max-width: $small-screen-separator-max) {
            font-size: 16px;
        }

        @media (min-width: $small-screen-separator-min) {
            font-size: 24px;
        }

        font-weight: bold;
        margin: unset;
    }

    .full-height-column {
        display: flex;
        align-items: center;
    }

    svg.navigable {
        cursor: pointer;
    }

    .top-content {
        z-index: 2;
    }

    .banner-row {
        width: 100%;
        position: relative;
    }

    .banner-image {
        @media (max-width: 600px) {
            width: 75px;
        }
    }

    .banner-row-borrower {
        width: 100%;
        position: absolute;
        padding-top: 20px;
        background-color: var(--borrower-background-color);
    }

    .right-aligned-col {
        display: flex;
        flex-direction: row-reverse;
    }

    .right-fading-column {
        background: var(--borrower-fading-column-right-gradient);
    }
}

.accounting-button {
    height: 48px;
    width: 48px;
    padding: 0;
    margin-bottom: 5px;
}

.small-accounting-button {
    height: 24px;
    width: 24px;
    padding: 0;
}

.accounting-image-container {
    display: flex;

    span {
        height: 30px;
        width: 30px;
        margin-right: 10px;
        border-radius: 4px;
    }
}

.excel-upload-icon {
    background-image: url("../images/Excel.png");
    background-size: cover;
}

.excel-upload-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.free-agent-icon {
    background-image: url("../images/freeagent.png");
    background-size: cover;
}

.free-agent-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.xero-icon {
    background-image: url("../images/xero.png");
    background-size: cover;
}

.xero-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.not-connected-icon {
}

.qbo-icon {
    background-image: url("../images/QuickBooks.png");
    background-size: cover;
}

.qbo-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.myob-icon {
    background-image: url("../images/myob.png");
    background-size: 90%;
    background-repeat: no-repeat;
    width: 48px;
    background-color: white;
    background-position: center;
}

.myob-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.reckon-icon {
    background-image: url("../images/reckon.png");
    background-size: cover;
}

.reckon-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.sage-intacct-icon {
    background-image: url("../images/sageIntacct.png");
    background-size: cover;
}

.sage-intacct-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.netsuite-icon {
    background-image: url("../images/netsuite.png");
    background-size: cover;
}

.netsuite-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.businesscentral-icon {
    background-image: url("../images/businesscentral.png");
    background-size: cover;
}

.businesscentral-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.example-icon {
    background-image: url("../images/dex.png");
    background-size: cover;
}

.example-icon:disabled {
    opacity: var(--disabled-button-opacity);
}

.progress {
    background-color: var(--strongbox-orange);
    width: 100%;
    height: 30px;
    border-radius: 0;
}

.borrower-progress {
    background-color: var(--strongbox-orange) !important;
}

.file-drop-target-dark {
    border: 1px dashed var(--dark-border);
}

.file-drop-target {
    width: 100%;
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px dashed var(--regular-border);
}

.dzu-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    h1 {
        margin-top: 24px;
        font-size: 24px;
        font-weight: normal;
        font-family: var(--font-family-primary);
        color: var(--target-text);
    }
}

.dropzone-label {
    margin-top: 50px;
    padding: 14px 25px 12px 25px;
    border-radius: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    border: none;
    background-color: var(--normal-button-color);
    color: var(--normal-button-text-color);
    font-family: var(--font-family-primary);
}

.dropzone-label:focus {
    outline: 3px solid var(--input-border);
    outline-offset: 2px;
}

.available-screen {
    position: absolute;
    left: 0;
    top: var(--banner-height);
    right: 0;
    bottom: 0;
}

.file-error-list {
    font-family: var(--font-family-primary);

    table {
        width: 100%;
    }

    .column-headers {
        color: var(--column-headers);
        border-bottom: 2px solid var(--border-color);
        border-top: 1px solid var(--row-dividers);
        font-size: 16px;

        th {
            padding-bottom: 19px;
            padding-top: 31px;
        }
    }

    .normal-rows {
        border-bottom: 1px solid var(--row-dividers);

        th {
            padding-bottom: 26px;
            padding-top: 33px;
            font-size: 16px;
            font-weight: normal;
            color: var(--row-text);
        }

        .name {
            font-size: 16px;
            font-weight: bold;
            color: var(--row-text);
        }
    }
}

.spaced-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.spaced-row-centered {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.basic-vertical-spacing-thin {
    margin-bottom: 10px;
}

.vcentered-content-col {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.outlined-column-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    outline: 1px solid var(--div-outline-color);
    padding: 25px;
    flex-direction: column;

    div {
        flex-direction: row;
        justify-content: space-between;
    }
}

.bullet {
    width: 50px;
    height: 50px;
    background-color: var(--bullet-background);
}

.bullet-small {
    width: 8px;
    height: 8px;
}

.bullet-container {
    padding-right: 16px;
}

.emphasis-text {
    font-style: italic;
    text-transform: uppercase;
}

.pagination-controls-container {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;

    .pagination-button {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }
}

.workbook-grouping-container {
    margin-top: 20px;
    padding: 15px 25px 15px 25px;
    outline-width: 1px;
    outline-style: solid;
    outline-color: var(--div-outline-color);

    .row {
        align-items: center;
    }

    h1 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0px;
    }

    h2 {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 0px;
    }

    .right-aligned-col {
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
    }

    .summary-row {
        background-color: lightgray;
    }

    .expanded-row {
    }

    span.highlight-content {
        background-color: var(--normal-button-color);
        color: var(--normal-button-text-color);
        padding: 10px 20px 10px 20px;
        font-size: 12px;
    }
}

.workbook-grouping-container-borderless {
    outline-style: none;
}

.running-jobs-container {
    .job-row-margined {
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 5px;
    }

    .job-row {
        margin-top: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .job-status {
        margin-left: 0px;
        display: flex;
        align-items: center;
    }

    .job-status-after {
        margin-right: 54px;
    }

    .job-status-error {
        flex-direction: column;
    }

    .job-item-row {
        align-items: center;

        .job-icon {
            width: 22px;
            height: 22px;
            justify-content: center;
        }

        span.job-icon {
            padding: 4px;
        }

        button.job-icon {
            padding: 4px;
        }
    }

    .job-column {
        display: flex;
        flex-direction: row;
        align-items: center;

        span.button-container {
            padding: 0;
            height: 28px;
        }

        button {
            max-width: 90%;
        }

        span.button-text {
            width: 100%;
            overflow-x: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            color: var(--normal-button-text-color);
        }
    }
}

.col-centered {
    display: flex;
    justify-content: center;
}

.flight-modal {
    @include shadow(0, 3px, 6px);
    background: white;
    border-radius: 0;
    border: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    position: absolute;
    margin: auto;
    max-width: 80%;
    max-height: 90%;
    min-width: 350px;
    text-align: left;
    z-index: $modalZIndex;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) !important;
    color: var(--modal-primary-text);
    font-family: var(--font-family-primary);

    div.multi-content-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;

        span {
            text-wrap: nowrap;
        }
    }

    h1 {
        font-size: 16px;
        font-weight: bold;
        color: var(--modal-bold-header);
    }

    h4 {
        font-size: 16px;
        color: var(--header-gray);
    }

    h2, h3, h5, h6 {
        font-size: 16px;
        font-weight: bold;
        color: var(--header-gray);
    }

    .buttons {
        display: flex;
        flex-direction: row;
        margin-top: 30px;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px;
            text-align: center;
            width: 60px;
        }

        h3 {
            font-weight: bold;
            font-size: 24px;
        }

        h4 {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0px;
            color: var(--legal-text);
        }

        .button-container {
            div.beta-descriptor {
                justify-content: center;

                h1 {
                    font-size: 16px;
                    font-weight: bold;
                    padding-top: 6px;
                    color: var(--beta-warning-text);
                    background: var(--beta-warning-background);
                    margin-top: 10px;
                    margin-bottom: 0px;
                    border-radius: 20px;
                    width: 65px;
                    height: 32px;
                }
            }
        }

        .extra-button-content-icon {
            width: 20px;
            height: 20px;
            margin-left: 16px;
            color: var(--error-text);
        }
    }

    .modal-content {
        background-color: white;
        border-radius: 0;
    }

    .flight-modal__close {
        position: absolute;
        right: var(--modal-padding);
        top: var(--modal-padding);
        cursor: pointer;
    }

    .flight-modal__close:hover {
        filter: brightness(0.85);
    }

    .flight-modal__actions {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }

    a {
        opacity: 0.8;
        color: var(--input-text-color);
    }

    input[type=text], input[type=email] {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5px;
        border-radius: 0;
        border: 1px solid var(--input-border);
        background-color: var(--input-background);
        color: var(--input-text-color);
    }

    .modal-button {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .modal-button:active {
        transform: translateY(2px) translateX(2px);
    }

    .submit-modal-button {
        background-color: var(--normal-button-color);
        color: var(--normal-button-text-color);

        &:disabled {
            background-color: var(--disabled-button-color);
            color: var(--disabled-button-text-color);
        }
    }

    .cancel-modal-button {
        margin-right: 20px;
        background-color: var(--cancel-button-color);
        color: var(--cancel-button-text-color);

        &:disabled {
            background-color: var(--disabled-button-color);
            color: var(--disabled-button-text-color);
        }
    }

    .btn-primary {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
        background-color: var(--normal-button-color);
        color: var(--normal-button-text-color);

        &:disabled {
            background-color: var(--disabled-button-color);
            color: var(--disabled-button-text-color);
        }

        &:active {
            transform: translateY(2px) translateX(2px);
            background-color: var(--light-strongbox-orange);
        }
    }

    .btn-thin-horizontal-padding {
        padding-left: 8px;
        padding-right: 8px;
    }

    .btn-strongbox {
        background-color: var(--strongbox-button-color);
        color: var(--strongbox-button-text-color);

        &:disabled {
            background-color: var(--disabled-button-color);
            color: var(--disabled-button-text-color);
        }
    }

    .btn-strongbox:focus {
        outline: 3px solid var(--input-border);
        outline-offset: 2px;
    }

    .btn-secondary {
        background-color: var(--cancel-button-color);
        color: var(--cancel-button-text-color);

        &:disabled {
            background-color: var(--disabled-button-color);
            color: var(--disabled-button-text-color);
        }
    }

    .disabled {
        opacity: var(--disabled-button-opacity);
    }

    .faded {
        opacity: 0.50;
    }

    .modal-group-header-row {
        margin-top: 15px;
    }

    .error-text {
        font-family: var(--font-family-primary);
        color: var(--error-text);
        text-decoration: none;
    }

    .emphasis-text {
        font-size: 16px;
        font-weight: bold;
    }

    .button-row-spaced {
        justify-content: space-between;
    }

    .button-row {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
    }

    .button-row-centered {
        width: 100%;
        display: flex;
        justify-content: center;

        button.not-leftmost {
            margin-left: 25px;
        }
    }

    .form-check-no-padding {
        padding: unset;
    }

    .btn {
        border-radius: 0;
    }

    input[type=number] {
        border-radius: 0;
    }

    a.normal-link-color {
        color: var(--normal-link-color);
    }

    .accounting-button-container {
        margin-top: 20px;

        .accounting-button-group-column {
            border-right: 1px solid var(--border-color);
            padding-top: 10px;
        }

        .accounting-button-summary-column {
            padding-top: 10px;
            text-align: left;
            position: relative;

            .accounting-package-image {
                width: 100%;
            }

            .bottom-row {
                margin-top: 35px;
                margin-bottom: 15px;
            }

            .bottom-column {
                display: flex;
                justify-content: center;
            }

            span {
                text-decoration: none;
                font-size: 16px;
            }

            .text-row {
                margin-top: 15px;
            }

            .instruction-row {
                height: 100%;
            }

            .instructions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px 20px 20px 20px;
            }

            .no-selection-image {
                width: 100%;
                height: fit-content;
            }

            .accounting-image-column {
                display: flex;
                justify-content: center;
            }

            .banner-image {
                width: 100%;
            }
        }

        .button-group-container {
            span {
                @media (max-width: $med-screen-separator-max) {
                    font-size: 14px !important;
                }

                @media (min-width: $med-screen-separator-min) {
                    font-size: 18px !important;
                }
            }

            table {
                margin-bottom: unset;
            }

            table tr {
                text-align: left;
                color: var(--light-gray-background-text);
                border: none;
            }

            tr th {
                padding: 2px 0px 17px 5px;
                border: none;
            }

            th span {
                font-size: 18px;
                font-weight: bold;
            }

            table td {
                padding: 2px 5px 2px 35px;
                text-align: left;
                background-color: var(--form-background);
                border: none;
                cursor: pointer;
            }

            table td.disabled {
                cursor: not-allowed;
            }

            table span {
                text-decoration: none;
            }

            table span.group-name {
                cursor: default;
            }

            table span.accounting-package:hover {
                border: 1px solid var(--dark-border);
                border-radius: 0;
                padding: 2px 15px 2px 5px;
            }

            table span.disabled {
                cursor: not-allowed;
            }
        }
    }

    input[type="checkbox"] {
        background-color: transparent;
        border-color: var(--normal-button-color);
    }

    input[type="checkbox"]:checked {
        background-color: var(--normal-button-color);
        border-color: var(--normal-button-color);
    }

    input[type="checkbox"].form-check-input, input[type="radio"].form-check-input {
        background-color: transparent;
        border-color: var(--normal-button-color);
    }

    input[type="checkbox"].form-check-input:checked, input[type="radio"].form-check-input:checked {
        background-color: var(--normal-button-color);
        border-color: var(--normal-button-color);
    }

    input[type="checkbox"].form-check-input:indeterminate {
        background-color: var(--normal-button-color);
        border-color: var(--normal-button-color);
    }

    button.dropdown-item:active {
        background-color: var(--strongbox-orange);
    }

    button.dropdown-item.active {
        background-color: var(--strongbox-orange);
    }
}

.basic-layout-container {
    padding: 15px 25px 15px 25px;

    .std-form-row {
        margin-bottom: 1.5em;
        align-items: center;
    }

    .left-aligned-label {
        text-align: left;
        padding-left: 0;
    }

    .right-aligned-label {
        text-align: right;
    }

    .std-summary-column {
        @media (min-width: 992px) {
            border-left: 1px solid var(--border-color);
        }
    }

    .border-container {
        border: 1px solid var(--border-color);
        border-radius: 4px;
        padding: 10px 25px 10px 50px;
    }
}

.portal-tab-content-raised {
}

.portal-tab-content-normal {
    padding-top: 1.5em;
}

.portal-tabs {
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    border: none;
    position: relative;

    .portal-nav-item {
        cursor: pointer;
        background-color: var(--form-background);
    }

    .portal-nav-item-inactive {
        color: var(--menu-gray-text) !important;

        a {
            color: unset;
        }
    }

    .portal-nav-item-active {
        border-bottom: 4px solid var(--active-nav-link);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        a {
            color: var(--active-nav-link);
        }
    }

    .portal-nav-link {
        font-size: 14px;
        padding: 0px 10px 0px 10px;
        text-decoration: unset;
    }

    .portal-nav-link-active {
    }
}

.tabs-right {
    justify-content: flex-end;
}

.column-list-container {
    div.multiline-cell {
        display: flex;
        flex-direction: column;
    }
}

.resource-overview-details {
    @media (max-width: 992px) {
        height: 65%;
    }

    @media (min-width: 992px) {
        border-left: 1px solid var(--border-color);
        height: 100%;
    }
}

.resource-overview-list {
    height: 100%;
    display: flex; 
    flex-direction: column;
}

.resource-overview {
    .details-view {
        padding: 20px 40px 20px 40px;
    }
}

.std-number-field {
    margin-top: 1.5em;
}

.header-user-name {
    margin-right: 1em;
    font-weight: bold;
    font-size: 14px;
    background-color: var(--off-white-background);
    padding: .5em 1em .5em 1em;
}

.header-right-contents {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.submissions-options-container {
    margin-left: 0.5em;
}

.portal-options-icon {
    width: 16px;
    height: 16px;
}

.portal-options-icon-container {
    position: relative;
    top: -4px;
}

.portal-options-dropdown {
    button {
        background-color: transparent;
        border-radius: unset;
        padding: 3px !important;
        box-shadow: unset;
        color: #000;
        font-size: 16px;
        font-weight: normal;
    }
}

.portal-options-dropdown-menu {
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 0;
}

.portal-options-dropdown-divider {
}

.date-picker-invisible-selection {
    .react-datepicker__day--selected {
        background-color: transparent;
        color: #000;
    }
}

.date-picker-container {
    button {
        background-color: transparent;
        box-shadow: none;
        height: 32px;
        width: 32px;
        padding: 5px 0px 0px 0px;
    }
}

.bottom-aligned-column {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
}

.history-submission-details-container {
    padding: 15px 40px 40px 40px !important;
}

.actionable-icon {
    cursor: pointer;
}

.sortable-table {
    th {
        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 32px;
        }
    }

    th.sortable {
        cursor: pointer;
    }

    .sort-icon {
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    .basic-list-icon {
        width: 24px;
        height: 24px;
    }

    .basic-list-icon-spacer {
        width: 24px;
        height: 24px;
        visibility: hidden;
    }

    div.list-box {
        display: flex;
        flex-direction: column;

        div.list-contents {
            display: flex;
            flex-direction: column;
            outline: 1px solid var(--border-color);
            padding: 2px 8px 2px 8px;
            margin-top: 10px;
            position: relative;
            left: -8px;

            span {
                font-size: 12px;
            }
        }
    }
}

.table-icon-column {
    display: flex;
    flex-direction: row;
}

.small-error-message {
    font-size: 14px !important;
}

.sticky-banner-container {
    position: sticky;
    top: 10px;
    background-color: var(--off-white-background);
    border: 3px solid var(--border-color);
    padding: 20px 20px 10px 50px;
    border-radius: 0;
    max-width: unset;
    z-index: 1;
}

.sticky-message {
    position: sticky;
    height: 4em;
    background-color: var(--off-white-background);
    top: 0px;
    left: 0px;
    z-index: 10;
    display: flex;
    align-items: center;
    padding-left: 40px;
    border-radius: 0;
    justify-content: center;
}

.fixed-message {
    position: fixed;
    height: 4em;
    background-color: var(--off-white-background);
    top: 20%;
    left: 10%;
    width: 80%;
    z-index: 200;
    display: flex;
    align-items: center;
    border-radius: 0;
    justify-content: center;
    border: 2px solid var(--dark-border);
    box-sizing: content-box;
}

.submissions-icon-no-data {
    mask: url('../images/no-data.svg');
}

.submissions-icon-data-available {
    mask: url('../images/data-available.svg');
}

.submissions-icon-anonymous-user {
    mask: url('../images/anonymous.svg');
}

.submission-icon-status-unknown {
    mask: url('../images/unknown-import-status.svg');
}

.submissions-icon-excel {
    height: 20px;
    width: 24px;
}

.submissions-icon-direct-import {
    mask: url('../images/direct-import.svg');
}

.right-aligned-col {
    display: flex;
    flex-direction: row-reverse;
}

$contentSummaryLinkColor: #1b1443;

$contentSummaryBackground: white;
$contentSummaryColumnHeaders: #707070;
$contentSummaryRowDividers: rgba(118,118,118,0.1);
$contentSummaryRowText: black;

$contentSummaryDivOutlineColor: lightgray;

.content-summary-container {
    display: flex;
    flex-direction: column;
    padding: 5px 5px 5px 5px;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 35px;
    background-color: $contentSummaryBackground;
    font-family: var(--font-family-primary);

    input[type=checkbox].large {
        height: 16px;
        width: 16px;
    }

    .clickable-item {
        cursor: pointer;
    }

    div.table-container {
        padding: 25px 15px 25px 15px;
        outline-width: 1px;
        outline-style: solid;
        margin-top: 10px;
        outline-color: $contentSummaryDivOutlineColor;

        h1 {
            font-size: 18px;
            font-weight: bold;
        }
    }

    div.header-row {
        display: flex;
        justify-content: space-between;
    }

    div.header-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
    }

    .data-container {
        padding: 10px 15px 10px 15px;
        margin-top: 0.75em;

        h1 {
            font-size: 16px;
            font-weight: bold;
        }

        h2, span {
            font-size: 16px;
            color: var(--text-gray);
            font-weight: normal;
        }

        label {
            font-size: 16px;
            color: var(--text-gray);
            font-weight: normal;
            margin: 0px;
        }

        .request-summary-row {
            input {
                margin: 0px;
            }

            div {
                display: flex;
                align-items: center;
            }
        }

        .error-text {
            color: var(--error-text);
        }
    }

    .bordered {
        border: 1px solid var(--border-color);
    }

    table {
        width: 100%;

        tr, td, th {
            padding: 2px;
        }
    }

    .column-headers {
        font-size: 16px;
        font-weight: normal;
        color: $contentSummaryColumnHeaders;
        border-bottom: 2px solid var(--regular-border);

        th {
            padding-bottom: 19px;
            padding-top: 31px;
        }

        th.slim {
            padding-bottom: 0px;
            padding-top: 0px;
        }
    }

    th.centered {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    tbody.centered-header {
        th {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }

    tbody.no-row-borders {
        th, td {
            border-top: none;
        }
    }

    thead.no-header-borders {
        th, td {
            border-top: none;
            border-bottom: none;
        }
    }

    .normal-rows {
        border-bottom: 1px solid $contentSummaryRowDividers;

        th, td {
            padding-bottom: 26px;
            padding-top: 33px;
            font-size: 16px;
            font-weight: normal;
            color: $contentSummaryRowText;

            &.name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 300px;
                min-width: 300px;
            }

            &.url {
                font-weight: bold;
                text-decoration: underline;
                color: $contentSummaryLinkColor;

                span:hover {
                    cursor: pointer;
                }
            }

            &.download {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .name {
            font-size: 16px;
            font-weight: bold;
            color: $contentSummaryRowText;
        }
    }

    .auto-delete {
        cursor: default !important;
        text-decoration: none;
        color: var(--paragraphtext);
    }

    .auto-delete-warning {
        background-color: yellow;
    }

    .auto-delete-deleted {
        background-color: red;
        color: white;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.warning-container {
    padding: 15px;
    border-radius: 6px;
    background-color: var(--light-gray-background);
    color: var(--light-gray-background)Text;
}

.warning-container {
    padding: 15px;
    border-radius: 6px;
    background-color: var(--light-gray-background);
    color: var(--light-gray-background)Text;
}

.message-banner {
    padding: 14px 30px 25px 30px;
    margin-bottom: 35px;
    color: var(--light-gray-background-text);
    border: 1px solid var(--div-outline-color);

    h1 {
        font-size: 36px;
        margin: unset;
        margin-bottom: 25px;
        color: var(--strongbox-orange);
    }

    h2 {
        font-size: 36px;
        margin: unset;
        margin-bottom: 25px;
        color: var(--strongbox-orange);
    }

    h3 {
        font-size: 24px;
        margin: unset;
        margin-bottom: 25px;
        color: var(--strongbox-orange);
    }

    p {
        margin-bottom: 0.5rem
    }

    a {
        font-size: 18px;
        text-decoration: underline;
    }

    .header-row {
        margin-bottom: 10px;
    }

    .footer-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .button-row {
        display: flex;
        margin-top: 10px;
        margin: 10px 50px 0px 0px;
    }

    .icon-column {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            height: 96px;
            width: 96px;
        }
    }

    button.secondary-button {
        height: 28px;
        width: 28px;
        padding: 0;

        svg {
            width: 18px;
            height: 18px;
        }
    }
}

.workspace-details-container {
    min-height: 550px;
    padding-bottom: 15px;
}

.workspace-details-wide-border {
    padding: 20px 40px 20px 40px;
}

.user-settings-container {
    margin-bottom: 25px;

    .settings-group {
        .settings-group-container {
            margin-top: 20px;
            padding-right: 15px;
        }

        .settings-group-container > div {
            border: 1px solid var(--border-color);
            padding: 10px 5px 10px 15px;
            height: 100%;
        }

        .selector-container {
            font-size: 12px;
            margin-top: 15px;

            input[type='checkbox'] {
                margin-right: 10px;
            }
        }
    }

    .action-group {
        display: flex;
        flex-direction: column;

        span {
            margin-top: 15px;
        }
    }

    .notification-container {
        .recipient-list {
            border: 1px solid var(--border-color);
            padding: 5px 20px 5px 5px;
            width: fit-content;
            max-width: 95%;
            overflow-x: auto;
        }

        .email-input-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 15px;

                button {
                    justify-content: center;
                    width: 18px;
                    height: 18px;
                    padding: 0;
                    margin-right: 10px
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .new-email-input {
            font-size: 12px;
            padding: 1px 1px 1px 10px;
            border: 1px solid var(--border-color);
            width: 250px;
        }

        .action-buttons {
            display: flex;
            flex-direction: row;
            margin-top: 10px;

            button {
                justify-content: center;
                width: 24px;
                height: 24px;
                padding: 0;
            }

            svg {
                width: 16px;
                height: 16px;
            }
        }

        span {
            font-size: 12px;
        }
    }
}

.workspace-user-list {
    .workspace-user-list-cell {
        border: none;
    }
}

div.popover {
    border-radius: 0;
}

.popover-header {
    border-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

div.dropdown-menu {
    border-radius: 0;
}


.info-popover {
    .popover {
    }

    .menu-style {
        font-size: 18px;
        font-weight: bold;
    }

    .popover-body {
        td {
            word-wrap: normal;
        }

        .header-column {
            font-weight: bold;
            padding-right: 15px;
            vertical-align: top;
            max-width: 230px;
        }

        .data-column {
            max-width: 250px;
            vertical-align: top;
        }

        .clickable {
            cursor: pointer;
        }

        button:not(.dropdown-toggle):not(.dropdown-menu):not(.dropdown-item):not(.regular-control):not(.style-button) {
            width: 48px;
            height: 32px;
            padding: 0px;
            background: transparent;
            color: var(--financial-statement-table-body-regular-text);
            justify-content: center;
            margin-left: 5px;
            border-radius: 2px;
        }

        div.selectable-row {
            padding: 8px;
            border-radius: 6px;
            cursor: pointer;
        }

        div.selectable-row:hover {
            background-color: var(--financial-statement-table-body-highlighted-row);
        }

        div.separator-line-bottom {
            border-top: 3px solid var(--strongbox-orange);
        }

        div.separator-line-top {
            border-bottom: 3px solid var(--strongbox-orange);
        }

        h1 {
            font-weight: bold;
            font-size: 14px;
        }
    }

    .btn-primary {
        background-color: var(--normal-button-color);
        color: var(--normal-button-text-color);

        &:disabled {
            background-color: var(--disabled-button-color);
            color: var(--disabled-button-text-color);
        }
    }

    .btn-secondary {
        background-color: var(--cancel-button-color);
        color: var(--cancel-button-text-color);
        margin-right: 15px;

        &:disabled {
            background-color: var(--disabled-button-color);
            color: var(--disabled-button-text-color);
        }
    }
}

.revision-link-container {
    display: flex;
    flex-direction: row;
    margin-left: 25px;
    margin-top: 10px;
}

.title-box-container {
    position: relative;
    border: 1px solid var(--border-color);
    padding-top: 15px;
    padding-bottom: 15px;

    span.title-box-title {
        position: absolute;
        left: 25px;
        top: -15px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 18px;
        font-weight: bold;
        background-color: var(--form-background);
    }
}

.row-with-delete, .row-with-delete-always-hide {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3px 0 3px 0;
    position: relative;
    justify-content: left;
    
    button {
        visibility: hidden;
        width: 16px;
        height: 16px;
        padding: 0;
        margin: 0;
        position: absolute;
        right: 0;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    div.placeholder {
        width: 30px;
        visibility: hidden;
    }
}

.row-with-delete:hover {
    button {
        visibility: visible;
    }
}

.tenant-disabled-container {
    p {
        font-size: 24px
    }
}

.extra-row-spacing-extra-small {
    margin-top: var(--extra-row-spacing-extra-small);
}

.extra-row-spacing-small {
    margin-top: var(--extra-row-spacing-small);
}

.extra-row-spacing-medium {
    margin-top: var(--extra-row-spacing-medium);
}

.extra-column-spacing-medium {
    margin-left: var(--extra-column-spacing-medium);
}

.extra-column-spacing-small {
    margin-left: var(--extra-column-spacing-small);
}

span.clickable {
    cursor: pointer;
    text-decoration: underline;
}

.standard-horizontal-row {
    display: flex;
    flex-direction: row;
}

.new-data-collection-modal {
}

.new-data-collection-modal-body {
    input[type="checkbox"] {
        cursor: pointer;
    }

    .main-content-container {
        overflow-y: auto;
        padding-bottom: 40px;
        max-height: 800px;

        .collection-options-container {
            max-width: 800px;
        }
    }

    .summary-container {
        display: flex;
        flex-direction: column;
        border-left: 1px solid var(--border-color);
        font-size: 12px;

        h1 {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 14px;
            font-weight: bold;
            text-decoration: underline;
        }

        span {
            margin-top: 10px;
            font-size: 12px;
        }

        div.go-button {
            display: flex;
            justify-content: center;

            button {
                width: fit-content;
                font-size: 12px;
            }
        }
    }
}

.import-financials-date-picker-custom-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
        width: 16px;
        height: 16px;
        padding: 0 0 1px 0 !important;

        svg {
            height: 14px;
            width: 14px;
            display: flex;
        }
    }

    .left-spacing {
        margin-left: 3px;
    }

    select {
        margin-bottom: 1px;
    }
}

.standalone-date-picker-container {
    position: relative;
    min-height: 240px;

    .react-datepicker {
        font-size: 0.7rem;
    }

    .react-datepicker-wrapper {
        display: none;
    }

    .react-datepicker-popper {
        transform: unset !important;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__week {
        font-size: 0.6rem;
    }

    .react-datepicker__day {
        width: 1.2rem;
        line-height: 1.2rem;
    }

    .react-datepicker__current-month {
        font-size: 0.7rem;
    }

    .react-datepicker__day-name {
        width: 0.9rem;
        line-height: 1.0rem;
    }
}

.user-metadata-container {
    div.user-metadata-row {
        margin-top: 10px;

        input {
            width: 100%;
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }

    div.invalid-tooltip {
        position: relative;
    }
}

.add-user-container {
    .edit-user-info-region {
        height: 360px;
        position: relative;
    }

    div.user-metadata-button-row {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 15px;
        position: absolute;
        bottom: 10px;
        right: 0px;
        width: 100%;

        div {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    input.form-control.is-valid {
        background-image: unset;
    }
}

@import './Settings/Settings.scss';
